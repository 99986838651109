




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import TemplateList from '@/components/templates/TemplateList.vue';

@Component({
    components: {
        TemplateList
    }
})
export default class CoverLetterTemplates extends Vue {
    public entity: string = 'cover-letter';
}
