























































import { Component } from 'vue-property-decorator';
import EditButton from '@/components/common/EditButton.vue';
import BaseCrudDialog from '@/components/base/BaseCrudDialog';
import TemplateInterface from '@/interfaces/TemplateInterface';
import CreateButton from '@/components/common/CreateButton.vue';
import RemoveButton from '@/components/common/RemoveButton.vue';

@Component({
    components: {
        EditButton,
        CreateButton,
        RemoveButton
    }
})
export default class TemplateDialog extends BaseCrudDialog {
    public previewPath: string = '';
    public previewImage: File = (new File([], '', undefined));
    public entityType: string = 'template';
    public entity: TemplateInterface = {} as TemplateInterface;

    public close(): void {
        this.dialog = false;

        this.previewPath = '';
        this.previewImage = (new File([], '', undefined));
    }

    public preview(): void {
        this.previewPath = URL.createObjectURL(this.previewImage);

        this.entity.file = this.previewImage;
    }

    public setTemplateImage(): void {
        if (this.entity.preview_image) {
            this.previewPath = `${process.env.VUE_APP_BACKEND_URI}/storage${this.entity.preview_image}`;
        }
    }

    public submit(): void {
        if (this.id) {
            this.update(this.id, this.prepareFormData()).then(() => {
                this.emitRefresh();

                this.close();
            });

            return;
        }

        this.create(this.prepareFormData()).then(() => {
            this.emitRefresh();

            this.close();
        });
    }

    public prepareFormData(): FormData {
        const formData = new FormData();

        if (this.id) {
            formData.append('_method', 'PUT');
        }

        Object.keys(this.entity).forEach(key => {
            // eslint-disable-next-line
            formData.append(key, (this.entity as Record<string, any>)[key]);
        });

        return formData;
    }
}
