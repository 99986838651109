import BaseApi from '@/services/api/BaseApi';

export default class TemplateApi extends BaseApi {
    protected type: string = 'resume-templates';

    public constructor(entity: string = 'resume') {
        super();

        if (entity === 'cover-letter') {
            this.type = 'cover-letters-templates';
        }
    }
}
