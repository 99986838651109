
















































import { DataTableHeader } from 'vuetify';
import BaseList from '@/components/base/BaseList';
import TemplateApi from '@/services/api/TemplateApi';
import Actions from '@/components/renderless/Actions';
import { Component, Prop } from 'vue-property-decorator';
import TemplateDialog from '@/components/templates/TemplateDialog.vue';

@Component({
    components: {
        Actions,
        TemplateDialog
    }
})
export default class TemplateList extends BaseList {
    @Prop({ required: false, type: String, default: 'resume' })
    public entity!: string;
    public apiClient = (new TemplateApi(this.entity));
    public headers: DataTableHeader[] = [
        { text: '№', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: '', value: 'actions', align: 'end' }
    ];
}
